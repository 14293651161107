import { MaterialSettings } from './types';

function dateReviver(this: any, key: string, value: any) {
  if (key === 'date') {
    return new Date(value);
  } else {
    return value;
  }
}

function dateReplacer(this: any, key: string, value: any) {
  if (value instanceof Date) {
    return value.getTime();
  } else {
    return value;
  }
}

export interface StoredMaterialSettings extends MaterialSettings {
  date: Date;
}

class SettingsRepository {
  private static KEY = 'savedCalculation';

  fetchAll(): StoredMaterialSettings[] {
    const item = localStorage.getItem(SettingsRepository.KEY);
    return item ? JSON.parse(item, dateReviver) : [];
  }

  fetchByDate(date: Date): StoredMaterialSettings | null {
    return this.fetchAll().filter(entry => entry.date.getTime() === date.getTime())[0] ?? null;
  }

  saveMaterialSettings(settings: MaterialSettings, date: Date) {
    const all = this.fetchAll();
    all.push({ ...settings, date });
    localStorage.setItem(SettingsRepository.KEY, JSON.stringify(all, dateReplacer));
  }

  deleteByDate(date: Date): void {
    const all = this.fetchAll();
    const without = all.filter(entry => entry.date.getTime() !== date.getTime());
    localStorage.setItem(SettingsRepository.KEY, JSON.stringify(without, dateReplacer));
  }
}

const settingsRepository = new SettingsRepository();
export default settingsRepository;
