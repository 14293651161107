import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

const BlueDivider = () => {
  const classes = useStyles();

  return <Divider variant="middle" classes={{ root: classes.divider }} />;
};

export default BlueDivider;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      // Theme Color, or use css color in quote
      background: theme.palette.primary.main,
      height: 2,
    },
  })
);
