import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { createStyles, IconButton, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SavedCalculationsContainer from './SavedCalculationsContainer';
import SettingsContainer from '../common/SettingsContainer';
import { MaterialSettings } from '../../data/types';
import { encodeQuery } from '../../utils/queryString';
import { useSettingsDispatch } from '../SettingsContext';
import settingsRepository, { StoredMaterialSettings } from '../../data/SettingsRepository';
import { formatDate } from '../../utils/formatting';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteOutlined } from '@material-ui/icons';
import { translateMaterial, translateQuality } from '../../utils/translateSettings';

const CalculationList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [savedCalculations, setCalculations] = useState<StoredMaterialSettings[]>([]);
  const dispatch = useSettingsDispatch();
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    setCalculations(settingsRepository.fetchAll());
  }, []);

  function deleteItem(date: Date) {
    settingsRepository.deleteByDate(date);
    setCalculations(settingsRepository.fetchAll());
  }

  function selectSavedSettings(selectedItem: MaterialSettings) {
    dispatch({
      type: 'SET_SETTINGS',
      settings: selectedItem,
    });
  }

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const dateParams = searchParams.get('dateString');

  return (
    <div>
      <SettingsContainer>
        <Typography variant={'h1'} component={'h2'}>
          {t('MyCalculations.SavedCalculations')}
        </Typography>
      </SettingsContainer>
      <SavedCalculationsContainer>
        {savedCalculations.map(({ date, ...selectedItem }) => {
          const dateString = date.toISOString();
          const obj = { ...selectedItem, dateString };

          return (
            <div className={classes.wrapper} key={date.getTime()}>
              <Link
                className={classes.link}
                onClick={() => selectSavedSettings(selectedItem)}
                to={{
                  pathname: isMobile ? '/result' : '/saved',
                  search: encodeQuery(obj),
                }}
              >
                <div className={dateParams !== dateString ? classes.container : classes.containerSelected}>
                  <>
                    <Typography className={classes.text} variant={'subtitle1'}>
                      {translateMaterial(t, selectedItem.material)}, {selectedItem.thickness}mm,{' '}
                      {translateQuality(t, selectedItem.quality)}, {formatDate(date)}
                    </Typography>
                  </>
                </div>
              </Link>
              {dateParams === dateString || isMobile ? (
                <div className={classes.delete}>
                  <IconButton onClick={() => deleteItem(date)}>
                    <DeleteOutlined style={{ color: 'white' }} />
                  </IconButton>
                  {/*     <Typography onClick={() => deleteItem(date)} className={classes.text} variant={'subtitle1'}>
                  {t('MyCalculations.delete')}
                </Typography>*/}
                </div>
              ) : null}
            </div>
          );
        })}
      </SavedCalculationsContainer>
    </div>
  );
};

export default CalculationList;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: { textDecoration: 'none', width: '100%' },
    root: {
      margin: theme.spacing(2, 1, 2, 1),
      padding: theme.spacing(0, 1),
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    delete: {
      width: 100,
      alignItems: 'center',
      height: 60,
      display: 'flex',
      backgroundColor: theme.palette.secondary.main,
    },
    container: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-start',
      alignContent: 'center',
      paddingLeft: theme.spacing(2),

      width: '100%',
      color: theme.palette.primary.main,

      height: 60,
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        background: theme.palette.secondary.main,
        color: theme.palette.common.white,
      },
    },
    containerSelected: {
      display: 'flex',
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(2),
      alignContent: 'center',
      width: '100%',
      color: theme.palette.common.white,

      height: 60,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        background: theme.palette.secondary.main,
        color: theme.palette.common.white,
      },
    },
    title: {},
    text: {
      alignSelf: 'center',
    },
    list: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth: 320,
      },
      [theme.breakpoints.up('md')]: {
        minWidth: 370,
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: 350,
        maxWidth: 370,
      },
    },
  })
);
