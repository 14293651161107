import { useLocation } from 'react-router-dom';
import { CutQuality, MaterialSettings, MaterialType } from '../../data/types';
import { useEffect, useState } from 'react';
import { defaultSettings } from '../SettingsContext';

export function parseQuery(query: string): MaterialSettings | null {
  const searchParams = new URLSearchParams(query);

  const quality = searchParams.get('quality');
  const thickness = searchParams.get('thickness');
  const hourRate = searchParams.get('hourRate');
  const material = searchParams.get('material');

  return quality && thickness && hourRate && material
    ? {
        quality: quality as CutQuality,
        thickness: parseInt(thickness, 10),
        hourRate: parseInt(hourRate, 10),
        material: material as MaterialType,
      }
    : null;
}

export default function useSettingsFromQuery(useDefaultAsFallback: boolean): MaterialSettings | null {
  const { search } = useLocation();
  const [settings, setSettings] = useState<MaterialSettings | null>(parseQuery(search));

  useEffect(() => {
    setSettings(parseQuery(search));
  }, [search]);

  if (settings === null && useDefaultAsFallback) {
    return defaultSettings;
  } else {
    return settings;
  }
}
