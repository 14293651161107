import { constants, MILLIMETER_PER_METER } from './constants';
import { ProcessingMethod } from '../data/types';

/**
 * @param a
 * @param b base value
 */
export function changeInPercentage(a: number, b: number): number {
  return ((a - b) / b) * 100;
}

function cuttingLengthPerHour(speed: number, method: ProcessingMethod) {
  const c = constants[method];
  return speed * c.minutesPerHour;
}

function cuttingLengthPerHourInMeter(speed: number, method: ProcessingMethod) {
  const c = constants[method];
  return (speed * c.minutesPerHour) / 1000;
}

export function abrasivesPerMeter(speed: number, method: ProcessingMethod) {
  const c = constants[method];
  return c.abrasivesCostPerHour / cuttingLengthPerHourInMeter(speed, method);
}

export function energyPerMeter(speed: number, method: ProcessingMethod) {
  const c = constants[method];
  return c.energyCostPerHour / cuttingLengthPerHourInMeter(speed, method);
}

export function waterPerMeter(speed: number, method: ProcessingMethod) {
  const c = constants[method];
  return c.waterCostPerHour / cuttingLengthPerHourInMeter(speed, method);
}

export function personalPerMeter(speed: number, method: ProcessingMethod) {
  const c = constants[method];
  return c.personalCostPerHour / cuttingLengthPerHourInMeter(speed, method);
}

export function maintenancePerMeter(speed: number, method: ProcessingMethod) {
  const c = constants[method];
  const systemCostsPerMeter = c.maintenanceCostSystemPerHour / cuttingLengthPerHourInMeter(speed, method);
  const pumpCostsPerMeter = c.maintenanceCostPumpPerHour / cuttingLengthPerHourInMeter(speed, method);
  const nozzleCostsPerMeter = c.nozzlesCostPerHour / cuttingLengthPerHourInMeter(speed, method);
  return systemCostsPerMeter + pumpCostsPerMeter + nozzleCostsPerMeter;
}

export function capitalPerMeter(speed: number, method: ProcessingMethod) {
  const c = constants[method];
  const capitalCostsPerMeter_row1 = c.capitalCostsPerHour_row1 / cuttingLengthPerHourInMeter(speed, method);
  const capitalCostsPerMeter_row2 = c.capitalCostsPerHour_row2 / cuttingLengthPerHourInMeter(speed, method);

  return capitalCostsPerMeter_row1 + capitalCostsPerMeter_row2;
}

function cuttingLengthPerDay(speed: number, method: ProcessingMethod) {
  const c = constants[method];
  return cuttingLengthPerHour(speed, method) * c.hoursPerDay;
}

function cuttingLengthPerMonth(speed: number, method: ProcessingMethod) {
  const c = constants[method];
  return cuttingLengthPerDay(speed, method) * c.workdaysPerMonth;
}

function effectiveCuttingLengthPerMonth(speed: number, method: ProcessingMethod) {
  const c = constants[method];
  return cuttingLengthPerMonth(speed, method) * c.effectiveWorkFactor;
}

export function effectiveCuttingLengthPerMonthInMeter(speed: number, method: ProcessingMethod) {
  return effectiveCuttingLengthPerMonth(speed, method) / MILLIMETER_PER_METER;
}

export function totalCostsPerMeter(speed: number, method: ProcessingMethod) {
  const c = constants[method];
  return (
    (c.totalCostPerHour / (cuttingLengthPerMonth(speed, method) / (c.workdaysPerMonth * c.hoursPerDay))) *
    MILLIMETER_PER_METER
  );
}

export function totalCostPerMonth(speed: number, method: ProcessingMethod) {
  return (totalCostsPerMeter(speed, method) * effectiveCuttingLengthPerMonth(speed, method)) / MILLIMETER_PER_METER;
}

export function turnoverPerMeter(hourRate: number, speed: number, method: ProcessingMethod) {
  return hourRate / (cuttingLengthPerHour(speed, method) / MILLIMETER_PER_METER);
}

export function rawProfitPerMeterInjektor(hourRate: number, speed: number) {
  return (
    turnoverPerMeter(hourRate, speed, ProcessingMethod.injection) -
    totalCostsPerMeter(speed, ProcessingMethod.injection)
  );
}

export function rawProfitPerMeterSuspension(hourRate: number, speedInjektor: number, speedConsus: number) {
  return (
    turnoverPerMeter(hourRate, speedInjektor, ProcessingMethod.injection) -
    totalCostsPerMeter(speedConsus, ProcessingMethod.suspension)
  );
}

export function rawProfitPerMonthSuspension(hourRate: number, speedInjektor: number, speedConsus: number) {
  return (
    rawProfitPerMeterSuspension(hourRate, speedInjektor, speedConsus) *
    (effectiveCuttingLengthPerMonth(speedConsus, ProcessingMethod.suspension) / MILLIMETER_PER_METER)
  );
}

export function rawProfitPerMonthInjektor(hourRate: number, speedInjektor: number) {
  return (
    rawProfitPerMeterInjektor(hourRate, speedInjektor) *
    (effectiveCuttingLengthPerMonth(speedInjektor, ProcessingMethod.injection) / MILLIMETER_PER_METER)
  );
}
