import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import InfoBox from '../../../common/InfoBox';

interface IProps {
  title: string;
  dialogTitle?: string;
  dialogDescription?: string;
  headline?: boolean;
}

const GraphTitleWithDialog = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.flex}>
      <Typography
        variant={!props.headline ? 'h6' : 'h1'}
        className={classes.title}
        component={!props.headline ? 'h3' : 'h2'}
      >
        {props.title}
      </Typography>
      {props.dialogTitle && props.dialogDescription ? (
        <InfoBox fontSize={18} title={props.dialogTitle} description={props.dialogDescription} />
      ) : null}
    </div>
  );
};

export default GraphTitleWithDialog;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: { padding: theme.spacing(1, 0) },
    flex: {
      display: 'flex',
    },
  })
);
