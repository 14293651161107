import React, { useEffect } from 'react';
import { createStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SettingsContainer from '../common/SettingsContainer';
import MaterialList from './MaterialList';
import Thickness from './Thickness';
import HourRate from './HourRate';
import CutQualitySelector from './CutQualitySelector';
import { CutQuality, MaterialType } from '../../data/types';
import { useSettingsDispatch, useSettingsState } from '../SettingsContext';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { parseQuery } from '../common/useSettingsFromQuery';
import GraphTitleWithDialog from '../ResultPage/ChartComponents/BuildingBlocks/GraphTitleWithDialog';

const CalcSettings = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { material, thickness, hourRate, quality } = useSettingsState();
  const dispatch = useSettingsDispatch();

  const { search } = useLocation();

  useEffect(() => {
    const params = parseQuery(search);
    if (params !== null) {
      dispatch({
        type: 'SET_SETTINGS',
        settings: params,
      });
    }
  }, []);

  const handleMaterial = (material: MaterialType) => {
    dispatch({
      type: 'SET_MATERIAL',
      material,
    });
  };

  const handleQuality = (quality: CutQuality) => {
    dispatch({
      type: 'SET_QUALITY',
      quality,
    });
  };

  const handleHourRate = (hourRate: number) => {
    dispatch({
      type: 'SET_HOUR_RATE',
      rate: hourRate,
    });
  };

  const handleThickness = (mm: number) => {
    dispatch({
      type: 'SET_THICKNESS',
      mm,
    });
  };

  return (
    <SettingsContainer>
      <GraphTitleWithDialog
        headline={true}
        title={t('Calculator.MaterialTitle')}
        dialogTitle={t('Calculator.InfoCalcTitle')}
        dialogDescription={t('Calculator.InfoCalcDescription')}
      />
      <MaterialList onChange={handleMaterial} selectedMaterial={material} />
      <Thickness material={material} handleThickness={handleThickness} currentSelection={thickness} />
      <CutQualitySelector handleQuality={handleQuality} selectedQuality={quality} />

      <div className={classes.spacing} />

      <GraphTitleWithDialog
        headline={true}
        title={t('Calculator.CalculationBasis')}
        dialogTitle={t('Calculator.InfoHourRateTitle')}
        dialogDescription={t('Calculator.InfoHourRateDescription')}
      />
      <HourRate handleHourRate={handleHourRate} selectedRate={hourRate} />
    </SettingsContainer>
  );
};

export default CalcSettings;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacing: { height: theme.spacing(1) },
  })
);
