import React, { Dispatch, ReducerAction, useContext } from 'react';
import { CutQuality, MaterialSettings, MaterialType } from '../data/types';
import getValidSettings from '../data/validation';

export const defaultSettings = {
  quality: CutQuality.separating,
  thickness: 1,
  material: MaterialType.baustahl,
  hourRate: 125,
};

type Action =
  | { type: 'SET_MATERIAL'; material: MaterialType }
  | { type: 'SET_THICKNESS'; mm: number }
  | { type: 'SET_QUALITY'; quality: CutQuality }
  | { type: 'SET_HOUR_RATE'; rate: number }
  | { type: 'SET_SETTINGS'; settings: MaterialSettings };

const SettingsStateContext = React.createContext<MaterialSettings>(defaultSettings);
const SettingsDispatchContext = React.createContext<Dispatch<ReducerAction<typeof settingsReducer>> | undefined>(
  undefined
);

function settingsReducer(state: MaterialSettings, action: Action) {
  switch (action.type) {
    case 'SET_MATERIAL': {
      const updatedState = { ...state, material: action.material };
      return getValidSettings(updatedState);
    }
    case 'SET_THICKNESS': {
      return { ...state, thickness: action.mm };
    }
    case 'SET_QUALITY': {
      return { ...state, quality: action.quality };
    }
    case 'SET_HOUR_RATE': {
      return { ...state, hourRate: action.rate };
    }
    case 'SET_SETTINGS': {
      return getValidSettings(action.settings);
    }
    default: {
      throw new Error(`Unhandled action type: ${(action as any).type}`);
    }
  }
}
export function SettingsProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(settingsReducer, defaultSettings);

  return (
    <SettingsStateContext.Provider value={state}>
      <SettingsDispatchContext.Provider value={dispatch}>{children}</SettingsDispatchContext.Provider>
    </SettingsStateContext.Provider>
  );
}

export const useSettingsState = () => {
  const context = useContext(SettingsStateContext);
  return {
    ...context,
  };
};

export const useSettingsDispatch = () => {
  const context = useContext(SettingsDispatchContext);
  return context!;
};
