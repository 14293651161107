import React from 'react';
import { createStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ResultContainer from './ResultContainer';
import InfoBoxConSus from './InfoBoxConSus';


const InfoContainer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ResultContainer>
      <div className={classes.flexWrapper}>
        <div >
          <Typography variant={'h1'}>{t('Calculator.InfoTitle')}</Typography>
          <Typography variant={'subtitle1'} className={classes.subtitle}>
            {t('Calculator.InfoSubTitle')}
          </Typography>
        </div>
        <div>
          <InfoBoxConSus />
        </div>
      </div>
    </ResultContainer>
  );
};

export default InfoContainer;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: { fontSize: '1.1em' },
    container: { padding: theme.spacing(0, 2) },
    flexWrapper: {
      display: 'flex',
      backgroundColor: theme.palette.common.white,
      //padding: theme.spacing(2),
      justifyContent: 'space-between',

    },
  })
);
