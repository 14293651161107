import React from 'react';
import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import deFlag from '../assets/deFlag.png';
import enFlag from '../assets/enFlag.png';
import ContactButtons from './common/ContactButtons';
import { useTranslation } from 'react-i18next';


const RightNavBarDesktop = () => {
  const classes = useStyles();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const { i18n } = useTranslation();
  return (
    <div className={classes.container}>
      <IconButton onClick={() => changeLanguage('en')} style={i18n.language === 'de' ? { opacity: 0.3 } : {}}>
        <img alt={"Englisch"} src={enFlag} />
      </IconButton>
      <IconButton onClick={() => changeLanguage('de')} style={i18n.language !== 'de' ? { opacity: 0.3 } : {}}>
        <img alt={"Deutsch"} src={deFlag} />
      </IconButton>
      <div className={classes.contactButtons}>
        <ContactButtons />
      </div>
    </div>
  );
};

export default RightNavBarDesktop;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contactButtons: {
      display: 'flex',
      alignItems: 'center',
      //padding: theme.spacing(2)
    },
    container: { display: 'flex' },
    iconButton: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.other.white,
      fontSize: 19,
      padding: theme.spacing(1),
      margin: theme.spacing(0, 1),
    },
  })
);
