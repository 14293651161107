import { createStyles, useTheme } from '@material-ui/core';
import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import StyledResponsiveBarChart from './BuildingBlocks/StyledResponsiveBarChart';
import { useTranslation } from 'react-i18next';
import { changeInPercentage, effectiveCuttingLengthPerMonthInMeter } from '../../../calculation/calculation';
import ResultContainer from '../../common/ResultContainer';
import GraphTitleWithDialog from './BuildingBlocks/GraphTitleWithDialog';
import { ProcessingMethod } from '../../../data/types';

interface IProps {
  speedSuspension: number;
  speedInjection: number;
}

const FeedBarChart = (props: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const speed = [
    {
      name: 'ConSus',
      suspension: props.speedSuspension,
      changeInPercent: props.speedInjection && changeInPercentage(props.speedSuspension, props.speedInjection),
      color: theme.palette.primary.main,
    },
    {
      name: t('Graph.Injektor'),
      injection: props.speedInjection && props.speedInjection,
      color: theme.palette.other.injektor,
    },
  ];
  const cuttingLengthPerMonthData = [
    {
      name: 'ConSus',
      suspension: effectiveCuttingLengthPerMonthInMeter(props.speedSuspension, ProcessingMethod.suspension),
      changeInPercent: props.speedInjection && changeInPercentage(props.speedSuspension, props.speedInjection),
      color: theme.palette.primary.main,
    },
    {
      name: t('Graph.Injektor'),
      injection:
        props.speedInjection && effectiveCuttingLengthPerMonthInMeter(props.speedInjection, ProcessingMethod.injection),
      color: theme.palette.other.injektor,
    },
  ];

  const classes = useStyles();

  return (
    <ResultContainer>
      <GraphTitleWithDialog title={t('Graph.Feed_mm')} />
      <div className={classes.container}>
        <StyledResponsiveBarChart data={speed} />
      </div>
      <GraphTitleWithDialog
        title={t('Graph.Output_m')}
        dialogTitle={t('Graph.FeedInfoTitle')}
        dialogDescription={t('Graph.FeedInfoDescription')}
      />
      <div className={classes.container}>
        <StyledResponsiveBarChart data={cuttingLengthPerMonthData} />
      </div>
    </ResultContainer>
  );
};

export default FeedBarChart;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 300,
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
    },
    title: { padding: theme.spacing(1, 0) },
    flex: {
      display: 'flex',
    },
  })
);
