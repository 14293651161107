import React from 'react';
import { ListItem, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import theme from '../../theme';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import ContactButtons from './ContactButtons';
import BlueDivider from './BlueDivider';
import { ListItemLink, ListItemWebLink } from './ListItems/ListItems';
import useWebShare from 'react-use-web-share';

const NavigationContent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const { isSupported, share } = useWebShare(onSuccess, onError);

  function onSuccess() {}

  function onError() {
    // alert("failed")
  }

  const { i18n } = useTranslation();

  const changeLanguage = () => {
    if (i18n.language === 'de') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('de');
    }
  };

  return (
    <div className={classes.background}>
      <List className={classes.mainMenu}>
        <ListItemLink to={isMobile ? '/' : '/result'} activeOn={['/', '/result']}>
          <ListItemText
            disableTypography={true}
            primary={
              <Typography variant={'h1'} component={'h2'}>
                {t('Menu.Calculate')}
              </Typography>
            }
          />
        </ListItemLink>
        <ListItemLink to={'/saved'}>
          <ListItemText
            disableTypography={true}
            primary={
              <Typography variant={'h1'} component={'h2'}>
                {t('Menu.MyCalculations')}
              </Typography>
            }
          />
        </ListItemLink>
      </List>
      <div className={classes.secondMenu}>
        <List>
          <ListItemWebLink href={t('Menu.url.More')} target={'_blank'} rel="noopener noreferrer">
            <ListItemText primary={t('Menu.More')} />
          </ListItemWebLink>
          <ListItemWebLink
            id={'sample'}
            onClick={() => window.trackboxx('trackGoal', 'GZPDXFNJED', 0)}
            href={'mailto:sales@consus-gmbh.com'}
            target={'_blank'}
            rel="noopener noreferrer"
          >
            <ListItemText primary={t('Menu.Request')} />
          </ListItemWebLink>
          {isSupported && (
            <ListItem
              button
              id={'share'}
              onClick={() => {
                window.trackboxx('trackGoal', 'STJKEYEQ68', 0);
                share({
                  url: 'http://waterjet-calculator.consus-gmbh.com',
                  title: 'ConSus - Calc a Cut',
                  text: 'Vergleich ConSus – Injektor',
                });
              }}
            >
              <ListItemText primary={t('Menu.Share')} />
            </ListItem>
          )}
        </List>
      </div>
      <BlueDivider />
      <div className={classes.thirdMenu}>
        <List>
          <ListItem className={classes.root} component={'nav'} button onClick={() => changeLanguage()}>
            <ListItemText primary={t('Menu.Language')} />
          </ListItem>
          <ListItemWebLink href={t('Menu.url.Imprint')} target={'_blank'} rel="noopener noreferrer">
            <ListItemText primary={t('Menu.Imprint')} />
          </ListItemWebLink>
          <ListItemWebLink href={t('Menu.url.PrivacyPolicy')} target={'_blank'} rel="noopener noreferrer">
            <ListItemText primary={t('Menu.PrivacyPolicy')} />
          </ListItemWebLink>
        </List>

        <div className={classes.bottomWrapper}>
          <Typography className={classes.version} variant={'subtitle2'}>
            v{process.env.REACT_APP_VERSION}
          </Typography>

          <div className={classes.contactButtons}>
            <ContactButtons />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationContent;

const useStyles = makeStyles({
  version: {
    fontSize: 11,
    opacity: 0.8,
    padding: theme.spacing(2),
  },
  bottomWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contactButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 3, 3, 0),
  },
  secondMenu: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  thirdMenu: {
    justifyContent: 'flex-end',
  },
  root: {
    '&$selected': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
  },

  background: {
    backgroundColor: theme.palette.other.lightBlue,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.primary.main,
  },
  mainMenu: {
    backgroundColor: theme.palette.other.white,
    paddingTop: 4,
    width: 280,
  },
});
