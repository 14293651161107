import * as React from 'react';

interface IProps {
  width: number;
  color: string;
  children: React.ReactNode;
  fontSize?: number;
  x?: number;
  y?: number;
  fontWeight?: string;
}
const SVGText = (props: IProps) => {
  const { width,  color, children, fontSize, x, y } = props;
 const top =  -15
  return (
    <text
      x={x || width / 2}
      y={y || top}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: fontSize || 16,
        fontWeight: 'bold',
        pointerEvents: 'none',
        fill: color,
      }}
    >
      {children}
    </text>
  );
};
export default SVGText;
