import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { MaterialSettings } from '../../data/types';
import theme from '../../theme';
import ResultContainer from '../common/ResultContainer';
import { useTranslation } from 'react-i18next';
import { translateMaterial, translateQuality } from '../../utils/translateSettings';

interface IProps {
  appliedSettings: MaterialSettings | null;
}

const CurrentSettingsDesktopTablet = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ResultContainer
      style={{
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        border: '1px solid white',
        backgroundColor: theme.palette.other.lightBlue,
      }}
    >
      <div className={classes.container}>
        <Typography variant={'subtitle1'} className={classes.title}>
          {' '}
          {t('Graph.CurrentSettings')}
        </Typography>
        <Typography className={classes.text} variant={'subtitle1'}>
          {translateMaterial(t, props.appliedSettings?.material)}
        </Typography>

        <Typography className={classes.text} variant={'subtitle1'}>
          {props.appliedSettings?.thickness}mm
        </Typography>

        <Typography className={classes.text} variant={'subtitle1'}>
          {translateQuality(t, props.appliedSettings?.quality)}
        </Typography>

        <Typography className={classes.text} variant={'subtitle1'}>
          {props.appliedSettings?.hourRate}€ / {t('Graph.hour')}
        </Typography>
      </div>
    </ResultContainer>
  );
};

export default CurrentSettingsDesktopTablet;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-around',
      alignContent: 'center',
      width: '100%',
      height: 60,
      backgroundColor: theme.palette.primary.light,
    },
    text: {
      alignSelf: 'center',
    },
    title: {
      alignSelf: 'center',
      fontWeight: 'bold',
    },
  })
);
