import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import StructuralSteel from '../../assets/material/Baustahl.jpg';
import Aluminium from '../../assets/material/Alluminium.jpg';
import StainlessSteel from '../../assets/material/Edelstahl.jpg';
import Titanium from '../../assets/material/Titan.jpg';
import Granit from '../../assets/material/Granit.jpg';
import Marmor from '../../assets/material/Mamor.jpg';
import Glass from '../../assets/material/glass.jpg';
import { useTranslation } from 'react-i18next';
import UpperCaseTitle from './UpperCaseTitle';
import ConSusMaterialItem from './ConSusMaterialItem';
import { MaterialType } from '../../data/types';

interface IProps {
  selectedMaterial: MaterialType;
  onChange: (materialType: MaterialType) => void;
}

const MaterialList = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const materials = [
    { name: t('Calculator.StructuralSteel'), img: StructuralSteel, type: MaterialType.baustahl },
    { name: t('Calculator.Aluminium'), img: Aluminium, type: MaterialType.aluminium },
    { name: t('Calculator.StainlessSteel'), img: StainlessSteel, type: MaterialType.edelstahl },
    { name: t('Calculator.Glas'), img: Glass, type: MaterialType.glas },
    { name: t('Calculator.Marble'), img: Marmor, type: MaterialType.marmor },
    { name: t('Calculator.Titanium'), img: Titanium, type: MaterialType.titan },
    { name: t('Calculator.Granite'), img: Granit, type: MaterialType.granit },
  ];

  return (
    <>
      <UpperCaseTitle title={t('Calculator.Material')} />
      <div className={classes.container}>
        {materials.map(material => (
          <ConSusMaterialItem
            onClick={props.onChange}
            key={material.type}
            material={material}
            active={material.type === props.selectedMaterial}
          />
        ))}
      </div>
    </>
  );
};

export default MaterialList;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' },
  })
);
