export const chartTheme = {
  textColor: '#333333',
  fontSize: 12,
  axis: {
    domain: {
      line: {

        //  stroke: '#333333',
       //   strokeWidth: 1,
              },
    },
    ticks: {
      line: {
        strokeWidth: 0,
      },
    },
  },
  grid: {
    line: {
      strokeWidth:0,
    },
  },
};
