export interface MaterialSettings {
  material: MaterialType;
  thickness: number;
  quality: CutQuality;
  hourRate: number;
}

export interface RawMaterialEntry {
  id: number;
  Material_de: string;
  mm: number;
  i_q1: number | null;
  i_q3: number | null;
  i_q5: number | null;
  s_q1: number | null;
  s_q3: number | null;
  s_q5: number | null;
}

export enum MaterialType {
  baustahl = 'Baustahl',
  aluminium = 'Aluminium',
  edelstahl = 'Edelstahl',
  glas = 'Glas',
  marmor = 'Marmor',
  titan = 'Titan',
  granit = 'Granit',
}

export enum CutQuality {
  separating = 'separating',
  medium = 'medium',
  fine = 'fine',
}

export interface CuttingSpeed{
  q1: number,
  q3: number,
  q5: number
}

export enum ProcessingMethod {
  injection = 'injection',
  suspension = 'suspension'
}
