import React from 'react';
import { useTheme } from "@material-ui/core";

const CustomTick = (tick: any) => {
  const theme = useTheme()

  return (
    <g transform={`translate(${tick.x},${tick.y + 22})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fill: tick.value === 'Injektor' || tick.value === 'Injector'? theme.palette.other.injektor : theme.palette.primary.main,
          fontSize: 18,
          fontWeight: 'bold',
        }}
      >
        {tick.value}
      </text>
    </g>
  );
};

export default CustomTick ;

