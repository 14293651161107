import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InfoBoxConSusItem from './InfoContainerConSusItem';
import SecondaryDivider from './SecondaryDivider';
import { expiryInfoBoxConSusStatue } from '../../utils/localStorage';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function InfoBoxConSus() {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    setOpen(expiryInfoBoxConSusStatue(168));
  }, []);
  const { t } = useTranslation();
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <InfoOutlined style={{ fontSize: 18 }} color={'primary'} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.icon}>
          <InfoOutlined fontSize={'large'} color={'secondary'} />
        </div>

        <DialogContent>
          <DialogTitle className={classes.title} id="alert-dialog-title">
            {t('Calculator.InfoBoxDialogTitle')}
          </DialogTitle>
          <DialogContentText className={classes.description} id="alert-dialog-description">
            {t('Calculator.InfoBoxDialogDescription')}
            <b> {t('Calculator.InfoBoxDialogDescription_bold')}</b>
          </DialogContentText>
          <div>
            <SecondaryDivider />
            <div className={classes.row}>
              <div className={classes.descriptionContainer}>
                <Typography className={classes.value} variant={'body1'}>
                  {t('Calculator.InfoConSus')}
                </Typography>
              </div>
              <div />

              <div className={classes.descriptionContainer}>
                <Typography className={classes.value} variant={'body1'}>
                  {t('Calculator.InfoInjektor')}
                </Typography>
              </div>
            </div>
            <SecondaryDivider />

            <div className={classes.row}>
              <InfoBoxConSusItem subtitle={t('Calculator.Compression')} value={t('Calculator.InfoCompressionConSus')} />
              <div />

              <InfoBoxConSusItem
                subtitle={t('Calculator.Compression')}
                value={t('Calculator.InfoCompressionInjektor')}
              />
            </div>
            <div className={classes.row}>
              <InfoBoxConSusItem subtitle={t('Calculator.NozzleWAS')} value={t('Calculator.InfoNozzleConSus')} />
              <div />

              <InfoBoxConSusItem subtitle={t('Calculator.NozzleWAIS')} value={t('Calculator.InfoNozzleInjektor')} />
            </div>
            <div className={classes.row}>
              <InfoBoxConSusItem
                subtitle={t('Calculator.FocusingTube')}
                value={t('Calculator.InfoFocusingTubeConSus')}
              />
              <div />

              <InfoBoxConSusItem
                subtitle={t('Calculator.FocusingTube')}
                value={t('Calculator.InfoFocusingTubeInjektor')}
              />
            </div>
            <div className={classes.row}>
              <InfoBoxConSusItem subtitle={t('Calculator.Abrasive')} value={t('Calculator.InfoAbrasiveConSus')} />
              <div />

              <InfoBoxConSusItem subtitle={t('Calculator.Abrasive')} value={t('Calculator.InfoAbrasiveInjektor')} />
            </div>

            <div className={classes.row}>
              <InfoBoxConSusItem subtitle={t('Calculator.Water')} value={t('Calculator.InfoWaterConSus')} />
              <div />

              <InfoBoxConSusItem subtitle={t('Calculator.Water')} value={t('Calculator.InfoWaterInjektor')} />
            </div>

            <div className={classes.row}>
              <InfoBoxConSusItem subtitle={t('Calculator.Performance')} value={t('Calculator.InfoPerformanceConSus')} />
              {isMobile ? <div /> : <div className={classes.equal}>=</div>}
              <InfoBoxConSusItem
                subtitle={t('Calculator.Performance')}
                value={t('Calculator.InfoPerformanceInjektor')}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t('General.Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    center: {
      alignContent: 'center',
    },
    equal: { display: 'flex', alignItems: 'center', fontSize: 25, color: theme.palette.secondary.main },
    row: {
      display: 'flex',
      justifyContent: 'space-evenly',

      marginBottom: theme.spacing(1),
      minWidth: 280,
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      paddingTop: theme.spacing(2),
    },
    subtitle: { fontSize: '1.1em' },
    description: {
      color: theme.palette.primary.main,
      whiteSpace: 'pre-line',
    },
    title: { fontWeight: 'bold', color: theme.palette.primary.main, padding: theme.spacing(0, 0, 1, 0) },
    value: { color: theme.palette.primary.main, fontSize: 16, fontWeight: 'bold' },
    descriptionContainer: { padding: theme.spacing(0, 0, 1, 0), minWidth: 120 },
  })
);
