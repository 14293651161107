import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties | undefined;
}

const ResultContainer = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.container)} style={{ ...props.style }}>
      {props.children}
    </div>
  );
};

export default ResultContainer;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: theme.spacing(1, 0),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
      minWidth: 320,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth: 320,
        margin: theme.spacing(1, 0),
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 600,
        margin: theme.spacing(2, 1, 1, 1),
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: 350,
        maxWidth: 600,
        margin: theme.spacing(2, 1),
      },
    },
  })
);
