import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import StandardBar from './StandardBar';
import { chartTheme } from '../chartTheme';
import CustomTick from './CustomTick';
import theme from '../../../../theme';
import CostsBarChart from './CostsBarChart';
import { formatDecimalNumber } from '../../../../utils/formatting';

interface IProps {
  data: object[];
  costs?: boolean;
}

const StyledResponsiveBarChart = (props: IProps) => {
  return (
    <ResponsiveBar
      barComponent={props.costs ? CostsBarChart : StandardBar}
      data={props.data}
      keys={['suspension', 'injection']}
      indexBy="name"
      theme={chartTheme}
      // maxValue={300}
      margin={{ top: props.costs ? 80 : 50, right: 10, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      // indexScale={{ type: 'band', round: true }}
      colors={[theme.palette.primary.main, theme.palette.other.injektor]}
      axisBottom={{
        renderTick: CustomTick,
      }}
      markers={[
        {
          // IDK why I had to add the ass keyword (typescript)
          axis: 'y',
          value: 0,
          lineStyle: { stroke: '#8a8a8a', strokeWidth: 1 },
        },
      ]}
      axisLeft={{
        tickValues: 5,
        format: value => {
          if (value > 1000000) {
            const calc = Number(value) / 1000000;
            return `${formatDecimalNumber(calc, 0, 0)} mio.`;
          } else {
            return formatDecimalNumber(Number(value), 0);
          }
        },
      }}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  );
};

export default StyledResponsiveBarChart;
