import { createStyles, useTheme } from '@material-ui/core';
import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import StyledResponsiveBarChart from './BuildingBlocks/StyledResponsiveBarChart';
import { useTranslation } from 'react-i18next';
import {
  changeInPercentage,
  rawProfitPerMeterInjektor,
  rawProfitPerMeterSuspension,
  rawProfitPerMonthInjektor,
  rawProfitPerMonthSuspension,
} from '../../../calculation/calculation';
import ResultContainer from '../../common/ResultContainer';
import GraphTitleWithDialog from './BuildingBlocks/GraphTitleWithDialog';

interface IProps {
  speedSuspension: number;
  speedInjection: number;
  hourRate: number;
}

const RevenueChart = (props: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  //// Compare turnover from Injektor with Costs from suspension
  const rawProfitSuspension = rawProfitPerMeterSuspension(props.hourRate, props.speedInjection, props.speedSuspension);
  const rawProfitInjection = rawProfitPerMeterInjektor(props.hourRate, props.speedInjection);



  const revenuePerMeter = [
    {
      name: 'ConSus',
      suspension: rawProfitSuspension,
      changeInPercent: changeInPercentage(rawProfitSuspension, rawProfitInjection),
      color: theme.palette.primary.main,
      format: '€',
    },
    {
      name: t('Graph.Injektor'),
      injection: rawProfitInjection,
      color: theme.palette.other.injektor,
      format: '€',
    },
  ];

  const rawProfitMonthSuspension = rawProfitPerMonthSuspension(props.hourRate, props.speedInjection, props.speedSuspension)
  const rawProfitMonthInjection = rawProfitPerMonthInjektor(props.hourRate, props.speedInjection) || 0

  const revenuePerMonth = [
    {
      name: 'ConSus',
      suspension:rawProfitMonthSuspension,
      changeInPercent: changeInPercentage(rawProfitMonthSuspension, rawProfitMonthInjection),
      color: theme.palette.primary.main,
      format: '€',
    },
    {
      name: t('Graph.Injektor'),
      injection: rawProfitMonthInjection,
      color: theme.palette.other.injektor,
      format: '€',
    },
  ];

  const classes = useStyles();

  return (
    <ResultContainer>
      <GraphTitleWithDialog dialogTitle={t('Graph.RevenueInfoTitle')} dialogDescription={t('Graph.RevenueInfoDescription')} title={t('Graph.RevenueMeter')} />

      <div className={classes.container}>
        <StyledResponsiveBarChart data={revenuePerMeter} />
      </div>

      <GraphTitleWithDialog dialogTitle={t('Graph.RevenueInfoTitle')} dialogDescription={t('Graph.RevenueInfoDescription')}  title={t('Graph.RevenueMonth')} />

      <div className={classes.container}>
        <StyledResponsiveBarChart data={revenuePerMonth} />
      </div>
    </ResultContainer>
  );
};

export default RevenueChart;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 300,
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
    },
    title: { padding: theme.spacing(1, 0) },
  })
);
