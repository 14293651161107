import React, { useEffect } from 'react';
import {
  createStyles,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UpperCaseTitle from './UpperCaseTitle';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { MaterialType } from '../../data/types';
import materialRepository from '../../data/MaterialRepository';

interface IProps {
  material: MaterialType;
  currentSelection: number;
  handleThickness: (mm: number) => void;
}

const Thickness = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [thicknesses, setThicknesses] = React.useState<number[]>(
    materialRepository.getThicknessesByMaterial(props.material)
  );

  useEffect(() => {
    const availableThicknesses = materialRepository.getThicknessesByMaterial(props.material);
    setThicknesses(availableThicknesses);
  }, [props.material]);

  const add = () => {
    const idx = thicknesses.findIndex(thicknesses => thicknesses === props.currentSelection);
    if (idx >= 0 && idx < thicknesses.length - 1) {
      props.handleThickness(thicknesses[idx + 1]);
    }
  };

  const sub = () => {
    const idx = thicknesses.findIndex(thicknesses => thicknesses === props.currentSelection);
    if (idx >= 1) {
      props.handleThickness(thicknesses[idx - 1]);
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const thickness = Number(event.target.value);
    props.handleThickness(thickness);
  };

  return (
    <div>
      <UpperCaseTitle title={t('Calculator.MaterialThickness')} />
      <div className={classes.container}>
        <FormControl size={"small"} margin="dense" variant="filled" className={classes.root}>
          <Select
            margin={"dense"}
            value={props.currentSelection}
            onChange={handleChange}
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'mm' }}
          >
            {thicknesses.map((data, i) => {
              return (
                <MenuItem key={i} value={data}>
                  {data} mm
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div className={classes.iconButton}>
          <IconButton onClick={() => add()}>
            <AddIcon color={'primary'} />
          </IconButton>
        </div>
        <div className={classes.iconButton}>
          <IconButton onClick={() => sub()}>
            <RemoveIcon color={'primary'} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default Thickness;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 0, 1, 0),
    },
    root: {
      flexGrow: 3,
    },
    selectEmpty: {
      minWidth: 146,

    },
    iconButton: {
      flexGrow: 1,
      textAlign: 'center',
    },
  })
);
