import { createStyles, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import StyledResponsiveBarChart from './BuildingBlocks/StyledResponsiveBarChart';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { changeInPercentage, totalCostsPerMeter } from '../../../calculation/calculation';
import theme from '../../../theme';
import CostDetailTable from './CostDetailTable';
import ResultContainer from '../../common/ResultContainer';
import GraphTitleWithDialog from './BuildingBlocks/GraphTitleWithDialog';
import { ProcessingMethod } from '../../../data/types';

interface IProps {
  speedSuspension: number;
  speedInjection: number;
}

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(248, 248, 248, .44)',
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const CostChart = (props: IProps) => {
  const { t } = useTranslation();

  const totalCostPerMeter = [
    {
      name: 'ConSus',
      suspension: totalCostsPerMeter(props.speedSuspension, ProcessingMethod.suspension),
      changeInPercent:
        props.speedInjection &&
        changeInPercentage(
          totalCostsPerMeter(props.speedSuspension, ProcessingMethod.suspension),
          totalCostsPerMeter(props.speedInjection, ProcessingMethod.injection)
        ),
      format: '€',
    },
    {
      name: t('Graph.Injektor'),
      injection: props.speedInjection && totalCostsPerMeter(props.speedInjection, ProcessingMethod.injection),
      format: '€',
    },
  ];

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <ResultContainer>
      <GraphTitleWithDialog title={t('Graph.Costs_m')} />
      <div className={classes.border}>
        <div className={classes.container}>
          <StyledResponsiveBarChart data={totalCostPerMeter} costs={true} />
        </div>
      </div>
      <div className={classes.borderBottom}>
        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
            <Typography>{expanded ? t('Graph.LessDetails') : t('Graph.MoreDetails')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CostDetailTable speedSuspension={props.speedSuspension} speedInjection={props.speedInjection} />
          </AccordionDetails>
        </Accordion>
      </div>
    </ResultContainer>
  );
};

export default CostChart;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 300,
      width: '100%',
    },
    border: {
      minHeight: 320,
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
      borderBottomStyle: 'none',
    },
    borderBottom: {
      borderWidth: 2,
      borderStyle: 'solid',

      borderColor: theme.palette.primary.main,
      borderTopStyle: 'none',
    },
    title: { padding: theme.spacing(1, 0) },
    details: { width: '100%', height: 30, backgroundColor: 'grey' },
  })
);
