import React from 'react';
import { createStyles, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core';
import theme from '../../theme';
import ResultContainer from '../common/ResultContainer';

interface IProps {
  title: string;
}

const ResultDivider = (props: IProps) => {
  const classes = useStyles();
  const isMobile = !useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

  return (
    <ResultContainer
      style={
        // ToDo: Das kann besser in useStyles gemacht werden (https://material-ui.com/customization/breakpoints/#css-media-queries)
        !isMobile
          ? {
              padding: theme.spacing(1),
              margin: theme.spacing(1, 1, -2, 1),
              backgroundColor: theme.palette.secondary.main,
            }
          : { backgroundColor: theme.palette.secondary.main }
      }
    >
      <div className={classes.container}>
        <Typography variant={'h5'} component={'h3'} className={classes.text}>
          {props.title}
        </Typography>
      </div>
    </ResultContainer>
  );
};

export default ResultDivider;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
      height: 50,
      justifyContent: 'center',
    },
    text: { color: theme.palette.other.white, alignSelf: 'center' },
  })
);
