import { BarItemProps } from '@nivo/bar';
import * as React from 'react';
import SVGText from './SVGText';
import NoInjektorData from './NoInjektorData';
import { formatDecimalNumber } from '../../../../utils/formatting';

const StandardBar = (props: BarItemProps) => {
  const { x, y, width, height, color, label, data } = props;
  return (
    <g transform={`translate(${x}, ${y})`}>
      <rect width={width} height={height} fill={color} strokeWidth="0" stroke="white" />
      {label ? (
        <SVGText width={width} color={color}>
          {formatDecimalNumber(Number(label), 2, 2)}
          {data.data.format && data.data.format}
        </SVGText>
      ) : (
        <NoInjektorData width={width} color={color} />
      )}
      {/*      {data.data.changeInPercent && (
        <SVGText width={width} color={"white"} x={width / 2} y={height / 4} fontSize={12}>
          {formatPercentage(data.data.changeInPercent as number)}
        </SVGText>

      )}*/}
    </g>
  );
};

export default StandardBar;
