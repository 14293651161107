import React from 'react';
import { ListItem, ListItemProps, makeStyles } from '@material-ui/core';
import { NavLink, LinkProps } from 'react-router-dom';
import theme from '../../../theme';
import * as H from 'history';

interface ListItemLinkProps {
  icon?: React.ReactElement;
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
  activeOn?: string[];
}

export function ListItemWebLink(props: ListItemProps<'a', { button?: true }>) {
  const classes = useStyles();

  return <ListItem className={classes.root} button component="a" {...props} />;
}

export function ListItemLink(props: ListItemLinkProps) {
  const { to, activeOn } = props;
  const classes = useStyles();

  const isActive = (match: any, location: H.Location) => activeOn?.includes(location.pathname) ?? false;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <NavLink
          to={to}
          ref={ref}
          {...itemProps}
          exact
          isActive={activeOn ? isActive : undefined}
          activeStyle={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
          }}
        />
      )),
    [to]
  );

  return (
    <li>
      <ListItem className={classes.root} button component={renderLink}>
        {props.children}
      </ListItem>
    </li>
  );
}

const useStyles = makeStyles({
  root: {
    '&$selected': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
    '&$selected:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
  },
});
