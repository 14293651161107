import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

const SecondaryDivider = () => {
  const classes = useStyles();

  return <Divider variant="middle" classes={{ root: classes.divider }} />;
};

export default SecondaryDivider;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      // Theme Color, or use css color in quote
      background: theme.palette.secondary.main,
      height: 2,
      margin: theme.spacing(1, 0),
    },
  })
);
