import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import MenuBar from './common/MenuBar';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties | undefined;
}

const Page = (props: IProps) => {
  const classes = useStyles();

  return (
    <>
      <MenuBar />
      <div className={classes.container} style={{ ...props.style }}>
        {props.children}
      </div>
    </>
  );
};

export default Page;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: theme.spacing(7, 0, 0, 0),
      height: '100%',
      backgroundColor: theme.palette.background.default,
    },
  })
);
