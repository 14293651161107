export function formatDecimalNumber(value: number, minimumFractionDigits = 1, maximumFractionDigits = 2) {
  return value.toLocaleString('DE-de', {
    minimumFractionDigits,
    maximumFractionDigits,
  });
}

export function formatPercentage(value: number) {
  return formatDecimalNumber(value, 1, 1) + "%";
}

export function formatDate(date: Date) {
  return date.toLocaleDateString();
}
