import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
  interface OtherColors {
    grey: string;
    white: string;
    lightBlue: string;
    lightGrey: string;
    injektor: string;
  }

  interface Palette {
    valid: string;
    other: OtherColors;
  }

  // allow configuration using `createMuiTheme`
  interface PaletteOptions {
    valid: string;
    other: OtherColors;
  }
}

// A custom theme for this app
const theme = createMuiTheme({
  overrides: {
    MuiSlider: {
      valueLabel: { color: 'transparent' },
    },
    MuiSelect: {
      root: {
        padding: '10px 12px',
        width: 'calc(100% - 24px)',
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '1.5em',
        fontWeight: 'bold',
        lineHeight: '1.4',
      },
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial',
  },
  palette: {
    primary: {
      main: '#003e78',
      light: '#ECF2F8',
    },
    secondary: {
      main: '#f8ae00',
    },
    error: {
      main: red.A400,
    },
    valid: '#24ca00',
    other: {
      grey: '#50E3AC',
      white: '#fff',
      lightBlue: '#ECF2F8',
      lightGrey: '#F5F5F5',
      injektor: '#A7876D',
    },
    background: {
      default: '#F5F5F5',
    },
  },
});

export default theme;
