import React, { useEffect } from 'react';
import { Button, createStyles, Theme, useMediaQuery } from '@material-ui/core';
import InfoContainer from '../common/InfoContainer';
import CalcSettings from './CalcSettings';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import ResultView from '../ResultPage/ResultView';
import { encodeQuery } from '../../utils/queryString';
import { useSettingsState } from '../SettingsContext';
import NavigationContent from '../common/NavigationContent';
import useHaveSettingsChanged from '../common/useHaveSettingsChanged';
import CalculationList from '../SavedCalculations/CalculationList';
import SaveButtonGroup from '../common/SaveButtonGroup';
import { makeStyles } from '@material-ui/core/styles';


const SettingsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isUpMobile = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));

  const { pathname } = useLocation();
  const showSavedEntries = pathname === '/saved';

  const settings = useSettingsState();
  const haveSettingsChanged = useHaveSettingsChanged(isUpMobile);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <div className={classes.wrapper}>
        {isDesktop && (
          <div className={classes.desktopNavigation}>
            <NavigationContent />
          </div>
        )}
        {isMobile && <InfoContainer />}
        <div className={classes.settings}>
          {showSavedEntries ? (
            <CalculationList />
          ) : (
            <>
              <CalcSettings />
              <div className={classes.buttonContainer}>
                {haveSettingsChanged && (
                  <Button
                    variant={'contained'}
                    className={classes.root}
                    component={Link}
                    id={'calculate'}
                    onClick={() => window.trackboxx("trackGoal", "GO3JEFIFNG", 0)}
                    to={{ pathname: '/result', search: encodeQuery(settings) }}
                    disabled={false}
                    color={'primary'}
                  >
                    {t('Calculator.Calc')}
                  </Button>
                )}
                {!haveSettingsChanged && !isMobile && <SaveButtonGroup showRequest={false} />}
              </div>
            </>
          )}
        </div>
        <div>{isUpMobile && <ResultView />}</div>

      </div>

    </>
  );
};

export default SettingsPage;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 55,
      width: 260,
      color: theme.palette.common.white,
      margin: theme.spacing(1),
      fontWeight: 'bold',
      fontSize: 16,
      fontVariant: 'normal',
      textTransform: 'none',
    },
    desktopNavigation: {
      height: 630,
      position: 'sticky',
      top: theme.spacing(9),
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1, 0, 4, 0),
    },
    wrapper: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },

      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      },
      [theme.breakpoints.up('lg')]: {},
    },
    settings: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: 500,
        margin: 'auto',
      },
      [theme.breakpoints.down('sm')]: {},
      [theme.breakpoints.up('md')]: {
        margin: 0,
        position: 'sticky',
        top: theme.spacing(7),
        height: 800,
      },
    },
  })
);
