import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { MaterialType } from '../../data/types';

export interface MaterialItem {
  name: string;
  img: string;
  type: MaterialType;
}

interface IProps {
  onClick: (type: MaterialType) => void;
  material: MaterialItem;
  active: boolean;
}

const ConSusMaterialItem = (props: IProps) => {
  const classes = useStyles();
  const { material } = props;
  return (
    <div onClick={() => props.onClick(material.type)} key={material.type} className={classes.imageContainer}>
      <img
        style={props.active ? { border: '5px', borderStyle: 'solid', opacity: 1 } : { opacity: 0.3 }}
        className={classes.img}
        height={50}
        src={material.img}
        alt={''}
      />
      <Typography
        className={classes.text}
        style={props.active ? { opacity: 1 } : { opacity: 0.5 }}
        color={'primary'}
        variant={'subtitle2'}
      >
        {material.name}
      </Typography>
    </div>
  );
};

export default ConSusMaterialItem;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      borderRadius: '50%',
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',

    },
    imageContainer: {
      flexDirection: 'column',
      width:82,
      alignContent:"center",
      margin: theme.spacing(0, 0, 1, 0),
    },
    text: { textAlign: 'center' , },
  })
);
