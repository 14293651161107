import React from 'react';
import { createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { SettingsOutlined } from '@material-ui/icons';
import { MaterialSettings } from '../../data/types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translateMaterial, translateQuality } from '../../utils/translateSettings';

interface IProps {
  appliedSettings: MaterialSettings | null;
}

const UserSelection = (props: IProps) => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.text} variant={'subtitle1'}>
        {translateMaterial(t, props.appliedSettings?.material)}, {props.appliedSettings?.thickness}mm,{' '}
        {translateQuality(t, props.appliedSettings?.quality)}, {props.appliedSettings?.hourRate}€ / {t('Graph.hour')}
      </Typography>
      <IconButton component={Link} to={'/'} size={'small'} color={'primary'}>
        <SettingsOutlined />
      </IconButton>
    </div>
  );
};

export default UserSelection;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-around',
      alignContent: 'center',
      width: '100%',
      height: 60,
      backgroundColor: theme.palette.primary.light,
      position: 'fixed',
      bottom: 0,
      left: 0,
    },
    text: {
      alignSelf: 'center',
    },
  })
);
