import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import SVGText from './SVGText';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import { InfoOutlined } from '@material-ui/icons';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import theme from '../../../../theme';

interface IProps {
  width: number;
  color: string;
}

const NoInjektorData = (props: IProps) => {
  const { width, color, } = props;
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>

      <rect onClick={() => handleClickOpen()}
            width={width} y={-105}
            x={0} height={80}
            fill={"white"}
            strokeWidth="0"
            stroke="white" />

      <path fill={theme.palette.primary.main} transform={`translate(${width / 2 - 12},${-105})`}
            d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z" />
      <SVGText width={width} color={color} y={-65}>
        {t('Graph.PowerLimitExeeded1')}
      </SVGText>
      <SVGText width={width} color={color} y={-45}>
        {t('Graph.PowerLimitExeeded2')}
      </SVGText>
      <SVGText width={width} color={color} y={-25}>
        {t('Graph.PowerLimitExeeded3')}
      </SVGText>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.icon}>
          <InfoOutlined fontSize={'large'} color={'secondary'} />
        </div>
        <DialogTitle className={classes.title} id="alert-dialog-title">
          {t('Graph.ExceededInfoTitle')}

        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.description} id="alert-dialog-description">
            {t('Graph.ExceededInfoDescription')}            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t('General.Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NoInjektorData;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      paddingTop: theme.spacing(2),
    },
    title: { fontWeight: 'bold', color: theme.palette.primary.main },
    description: { color: theme.palette.primary.main },
  })
);

