import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UpperCaseTitle from './UpperCaseTitle';
import CutQualityButton from './CutQualityButton';
import { CutQuality } from '../../data/types';

interface IProps {
  selectedQuality: CutQuality;
  handleQuality: (quality: CutQuality) => void;
}

const CutQualitySelector = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const qualityButtons = [
    { quality: CutQuality.separating, title: t('Calculator.Q1'), subtitle: t('Calculator.SeparatingCut') },
    { quality: CutQuality.medium, title: t('Calculator.Q3'), subtitle: t('Calculator.MediumQuality') },
    { quality: CutQuality.fine, title: t('Calculator.Q5'), subtitle: t('Calculator.FineCut') },
  ];

  return (
    <>
      <UpperCaseTitle title={t('Calculator.CuttingQuality')} />
      <div className={classes.container}>
        {qualityButtons.map(data => (
          <CutQualityButton
            key={data.quality}
            quality={data.quality}
            onClick={props.handleQuality}
            color={props.selectedQuality === data.quality ? 'primary' : 'default'}
            title={data.title}
            subTitle={data.subtitle}
          />
        ))}
      </div>
    </>
  );
};

export default CutQualitySelector;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { display: 'flex', justifyContent: 'space-between', padding: theme.spacing(0, 0, 1, 0) },
  })
);
