import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/ConSus_Logo.png';
import Logo_large from '../../assets/Logo_ConSus_l.png';
import NavigationDrawer from './NavigationDrawer';
import { useMediaQuery } from '@material-ui/core';
import RightNavBarDesktop from '../RightNavBarDesktop';

export default function MenuBar() {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.between(0, 1280));
  const isTablet = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));

  return (
    <AppBar className={classes.container} elevation={0} position="fixed">
      <Toolbar className={classes.bar}>
        <div className={classes.imageContainer}>
          <Link to="/">
            {isTablet ? (
              <img alt={'ConSus Logo'} src={Logo_large} className={classes.image} />
            ) : (
              <img alt={'ConSus Logo'} height={18} src={Logo} className={classes.image} />
            )}
          </Link>
        </div>

        <Typography variant={'h6'} className={classes.title}>
          {t('Calculator.Title')}
        </Typography>

        {isMobile ? <NavigationDrawer /> : <RightNavBarDesktop />}
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    container: { backgroundColor: theme.palette.other.white, alignItems: 'center' },
    imageContainer: { flexGrow: 1 },
    image: { padding: 2 },

    title: {
      flexGrow: 1,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: '0.9em',

      [theme.breakpoints.up('sm')]: {
        fontSize: '1em',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.3em',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.5em',
      },
    },
    bar: {
      backgroundColor: theme.palette.other.white,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        width: 992,
      },
      [theme.breakpoints.up('lg')]: {
        width: 1280,
      },
    },
  })
);
