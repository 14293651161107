export function expiryInfoBoxConSusStatue(duration: number) {
  const expiryDate = localStorage.getItem('expiry_date');
  const now = new Date().getTime();
  if (!expiryDate || (parseInt(expiryDate) + duration * 3600000) < now) {
    localStorage.setItem('expiry_date', (now).toString());
    return true;
  }

  return false;
}
