import { useSettingsState } from '../SettingsContext';
import useSettingsFromQuery from './useSettingsFromQuery';
import { shallowEqual } from '../../utils/equals';

export default function useHaveSettingsChanged(useDefaultAsFallback = true): boolean {
  const querySettings = useSettingsFromQuery(useDefaultAsFallback);
  const stateSettings = useSettingsState();

  return !shallowEqual(querySettings, stateSettings);
}
