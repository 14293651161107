import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, IconButton } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IProps {
  title: string;
  description: string;
  fontSize?: number | string ;
  padding?: number;
}

export default function InfoBox(props: IProps) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton style={{ padding: props.padding ?? 12 }} onClick={handleClickOpen}>
        <InfoOutlined style={{ fontSize: props.fontSize ?? 'inherit' }} color={'primary'} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.icon}>
          <InfoOutlined fontSize={'large'} color={'secondary'} />
        </div>
        <DialogTitle className={classes.title} id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.description} id="alert-dialog-description">
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t('General.Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      paddingTop: theme.spacing(2),
    },
    title: { fontWeight: 'bold', color: theme.palette.primary.main },
    description: {
      color: theme.palette.primary.main,
      whiteSpace: "pre-line",
},
  })
);
