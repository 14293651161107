import React from 'react';
import { makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import theme from '../../theme';
import NavigationContent from './NavigationContent';
import i18n from "i18next";
import enFlag from "../../assets/enFlag.png";
import deFlag from "../../assets/deFlag.png";
import Toolbar from "@material-ui/core/Toolbar";
import { useMediaQuery } from "@material-ui/core";

export default function NavigationDrawer() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const isTablet = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className={classes.container}>


      <IconButton  size={"small"} onClick={() => changeLanguage('en')} style={i18n.language === 'de' ? { opacity: 0.3 } : {}}>
        <img width={isTablet ? 30 : 18} alt={"Englisch"} src={enFlag} />
      </IconButton>
      <IconButton  size={"small"} onClick={() => changeLanguage('de')} style={i18n.language !== 'de' ? { opacity: 0.3 } : {}}>
        <img width={isTablet ? 30 : 18} alt={"Deutsch"}  src={deFlag} />
      </IconButton>

      <IconButton
        onClick={toggleDrawer(true)}
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
        <div
          className={classes.container}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <NavigationContent />
        </div>
      </Drawer>
    </div>
  );
}

const useStyles = makeStyles({
  container: { height: '100%' },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },

  menuButton: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    },

  },
  secondBackground: {
    backgroundColor: theme.palette.other.lightBlue,
  },
});
