import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core';
import theme from '../../theme';

interface IProps {
  handleHourRate: (handleHourRate: number) => void;
  setValue: (newValue: any) => void;
  value: number;
}

const CustomSlider = withStyles({
  root: {
    color: theme.palette.primary.main,
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: theme.palette.primary.main,
    marginTop: -14,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {},
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: theme.palette.primary.main,
      fontSize: '1.2em',
      fontWeight: 'bold',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

const RateSlider = (props: IProps) => {
  const classes = useStyles();

  function formatValue(value: number) {
    return `${value}€`;
  }

  return (
    <div className={classes.container}>
      <CustomSlider
        defaultValue={125}
        getAriaValueText={formatValue}
        valueLabelFormat={formatValue}
        aria-labelledby="discrete-slider-small-steps"
        step={5}
        onChange={(event, newValue) => props.setValue(newValue)}
        onChangeCommitted={(event, newValue) => props.handleHourRate(newValue as number)}
        value={props.value}
        min={70}
        max={150}
        valueLabelDisplay="on"
      />
    </div>
  );
};

export default RateSlider;

const useStyles = makeStyles({
  container: {
    width: window.innerWidth - 70,
    display: 'flex',
    margin: theme.spacing(2, 0,0,0),
  },
  thumb: {
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
    },
  },
  valueLabel: {
    '& *': {
      fontSize: '1.1em',
      fontWeight: 'bold',
    },
  },
});
