import { CutQuality, MaterialType } from '../data/types';
import { TFunction } from 'i18next';

export const translateMaterial = (tFunction: TFunction, material: MaterialType | undefined) => {
  switch (material) {
    case MaterialType.edelstahl:
      return tFunction('Calculator.StainlessSteel');
    case MaterialType.aluminium:
      return tFunction('Calculator.Aluminium');
    case MaterialType.baustahl:
      return tFunction('Calculator.StructuralSteel');
    case MaterialType.glas:
      return tFunction('Calculator.Glas');
    case MaterialType.marmor:
      return tFunction('Calculator.Marble');
    case MaterialType.titan:
      return tFunction('Calculator.Titanium');
    case MaterialType.granit:
      return tFunction('Calculator.Granite');
    default:
      return '';
  }
};

export const translateQuality = (t: TFunction, quality?: CutQuality): string => {
  switch (quality) {
    case CutQuality.fine:
      return t('Calculator.FineCut');
    case CutQuality.medium:
      return t('Calculator.MediumQuality');
    case CutQuality.separating:
      return t('Calculator.SeparatingCut');
    default:
      return '';
  }
};
