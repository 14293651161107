import React from 'react';
import { Button, createStyles, makeStyles, PropTypes, Theme, Typography } from '@material-ui/core';
import { CutQuality } from '../../data/types';

interface IProps {
  quality: CutQuality;
  title: string;
  subTitle: string;
  color: PropTypes.Color;
  onClick: (quality: CutQuality) => void;
}

const CutQualityButton = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonWrapper}>
      <Button
        onClick={() => props.onClick(props.quality)}
        className={classes.button}
        color={props.color}
        variant={'contained'}
      >
        {props.title}
      </Button>
      <Typography variant={'caption'}>{props.subTitle}</Typography>
    </div>
  );
};

export default CutQualityButton;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: { margin: theme.spacing(0.5, 1), padding: theme.spacing(1, 4.2) },
    buttonWrapper: { textAlign: 'center', display: 'flex', flexDirection: 'column' },
  })
);
