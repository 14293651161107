import React from 'react';
import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import { MailOutlineRounded, PhoneAndroidRounded } from '@material-ui/icons';


const ContactButtons = () => {
  const classes = useStyles();

  return (
    <>
      <IconButton href={'mailto:sales@consus-gmbh.com'} className={classes.iconButton}>
        <MailOutlineRounded />
      </IconButton>
      <IconButton href={'tel:+49451583800'} className={classes.iconButton}>
        <PhoneAndroidRounded />
      </IconButton>
    </>
  );
};

export default ContactButtons;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.other.white,
      fontSize: 19,
      padding: theme.spacing(1),
      margin: theme.spacing(0, 1),
    },
  })
);
