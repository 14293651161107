import { CutQuality, MaterialType, ProcessingMethod, RawMaterialEntry } from './types';
import materials from './material_detail_25_02_2021.json';

class MaterialRepository {
  constructor(private readonly data: RawMaterialEntry[]) {}

  getThicknessesByMaterial(material: MaterialType) {
    return this.data.filter(data => data.Material_de === material).map(entry => entry.mm);
  }

  getCuttingSpeedByMaterialAndThicknessAndQuality(
    material: MaterialType,
    thickness: number,
    quality: CutQuality
  ): Record<ProcessingMethod, number | null> | null {
    const result = this.data.filter(data => data.Material_de === material && data.mm === thickness);

    if (result.length > 0) {
      const entry = result[0];
      switch (quality) {
        case CutQuality.fine:
          return {
            [ProcessingMethod.injection]: entry.i_q5,
            [ProcessingMethod.suspension]: entry.s_q5,
          };
        case CutQuality.medium:
          return {
            [ProcessingMethod.injection]: entry.i_q3,
            [ProcessingMethod.suspension]: entry.s_q3,
          };
        case CutQuality.separating:
          return {
            [ProcessingMethod.injection]: entry.i_q1,
            [ProcessingMethod.suspension]: entry.s_q1,
          };
      }
    } else {
      return null;
    }
  }

  doesThicknessExist(material: MaterialType, thickness: number) {
    const result = this.data.filter(data => data.Material_de === material && data.mm === thickness);
    return result.length > 0;
  }

  getDefaultThicknessForMaterial(material: MaterialType) {
    return this.data.filter(data => data.Material_de === material)[0].mm;
  }
}

const materialRepository = new MaterialRepository(materials);
export default materialRepository;
