import {ProcessingMethod} from '../data/types';

export const MILLIMETER_PER_METER = 1000;

export class Constants {

  constructor(
    public readonly abrasivesCostPerHour: number,
    public readonly energyCostPerHour: number,
    public readonly waterCostPerHour: number,
    public readonly maintenanceCostPumpPerHour: number,
    public readonly maintenanceCostSystemPerHour: number,
    public readonly nozzlesCostPerHour: number,
    public readonly personalCostPerHour: number,
    public readonly capitalCostsPerHour_row1: number,
    public readonly capitalCostsPerHour_row2: number,
    public readonly minutesPerHour = 60,
    public readonly hoursPerDay = 8,
    public readonly workdaysPerMonth = 21,
    public readonly effectiveWorkFactor = 0.8,
  ) {}

  get totalMaintenanceCost(): number {
    return this.maintenanceCostPumpPerHour + this.maintenanceCostSystemPerHour;
  }

  get variableCostPerHour(): number {
    return (
      this.abrasivesCostPerHour +
      this.energyCostPerHour +
      this.waterCostPerHour +
      this.maintenanceCostSystemPerHour +
      this.maintenanceCostPumpPerHour +
      this.nozzlesCostPerHour +
      this.personalCostPerHour
    );
  }

  get totalCapitalCostPerHour(): number {
    return this.capitalCostsPerHour_row1 + this.capitalCostsPerHour_row2;
  }

  //ToDo: Where is this in the excel?
  get totalCapitalAndNozzleCostPerHour(): number {
    return this.totalCapitalCostPerHour + this.nozzlesCostPerHour;
  }

  get totalCostPerHour(): number {
    return this.totalCapitalCostPerHour + this.variableCostPerHour;
  }
}

const injectorConstants = new Constants(
  12,
  7.80044947935694,
  0.846842538071866,
  2.74336,
  3.08,
  1.25,
  22.0588235294118,
  25.398406374502,
  0
);

const suspensionConstants = new Constants(
  24,
  4.78652828395201,
  2.12821984067436,
  1.38084363636364,
  20.83,
  9.7,
  22.0588235294118,
  29.6314741035857,
  19.6713147410359
);

export const constants = {
    [ProcessingMethod.suspension]: suspensionConstants,
    [ProcessingMethod.injection]: injectorConstants,
}

// export const EFFECTIVE_CUTTING_LENGTH_PER_MONTH = 60 * 8 * 21 * 0.8;
// export const productionPerMonthInMeter = EFFECTIVE_CUTTING_LENGTH_PER_MONTH / 1000;

///// RESULT INJEKTOR
//export const TOTAL_COSTS_PER_HOUR_INJEKTOR = TOTAL_CAPITAL_COSTS_PER_HOUR_INJEKTOR + variableCostsPerHourInjektor;
//const TOTAL_COSTS_PER_METER_INJEKTOR = (TOTAL_COSTS_PER_HOUR_INJEKTOR / (cuttingLengthPerMonthInMM / (8 * 21))) * 1000;
//const TOTAL_COSTS_PER_MONTH_INJEKTOR = (EFFECTIVE_CUTTING_LENGTH_PER_MONTH * TOTAL_COSTS_PER_METER_INJEKTOR) / 1000;
//const TURNOVER_PER_MONTH_IN_METER_INJEKTOR = EFFECTIVE_CUTTING_LENGTH_PER_MONTH / 1000;
//const TURNOVER_TOTAL_COSTS_PER_METER_INJEKTOR =
// TOTAL_COSTS_PER_MONTH_INJEKTOR / (EFFECTIVE_CUTTING_LENGTH_PER_MONTH / 1000);

//// SUSPENSION
// export const ENERGY_PER_HOUR_SUSPENSION = 4.78652828395201;
// export const ABRASIVES_PER_HOUR_SUSPENSION = 24;
// export const WATER_PER_HOUR_SUSPENSION = 2.12821984067436;
// const maintenanceCostsPumpPerHourSuspension = 1.38084363636364;
//
// const maintenanceCostsSystemPerHourSuspension = 20.83;
// export const TOTAL_MAINTENANCE_COSTS_SUSPENSION =
//   maintenanceCostsPumpPerHourSuspension + maintenanceCostsSystemPerHourSuspension;
// export const NOZZLES_PER_HOUR_SUSPENSION = 9.7;
// export const PERSONAL_COSTS_PER_HOUR_SUSPENSION = 22.0588235294118;
// const variableCostsPerHourSuspension =
//   ABRASIVES_PER_HOUR_SUSPENSION +
//   ENERGY_PER_HOUR_SUSPENSION +
//   WATER_PER_HOUR_SUSPENSION +
//   maintenanceCostsPumpPerHourSuspension +
//   maintenanceCostsSystemPerHourSuspension +
//   NOZZLES_PER_HOUR_SUSPENSION +
//   PERSONAL_COSTS_PER_HOUR_SUSPENSION;
//
// const capitalCostsPerHourSuspension = 29.6314741035857;
// const capitalCostsPerHourSuspensionConSus = 19.6713147410359;
//
// export const TOTAL_CAPITAL_COSTS_PER_HOUR_SUSPENSION =
//   capitalCostsPerHourSuspension + capitalCostsPerHourSuspensionConSus;
//
// ///// RESULT
// export const TOTAL_COSTS_PER_HOUR_SUSPENSION = TOTAL_CAPITAL_COSTS_PER_HOUR_SUSPENSION + variableCostsPerHourSuspension;
// const TOTAL_COSTS_PER_METER_SUSPENSION =
//   (TOTAL_COSTS_PER_HOUR_SUSPENSION / (cuttingLengthPerMonthInMM / (8 * 21))) * 1000;
// const TOTAL_COSTS_PER_MONTH_SUSPENSION = (EFFECTIVE_CUTTING_LENGTH_PER_MONTH * TOTAL_COSTS_PER_METER_SUSPENSION) / 1000;
// const TURNOVER_PER_MONTH_IN_METER__SUSPENSION = EFFECTIVE_CUTTING_LENGTH_PER_MONTH / 1000;
// const TURNOVER_TOTAL_COSTS_PER_METER__SUSPENSION =
//   TOTAL_COSTS_PER_MONTH_SUSPENSION / (EFFECTIVE_CUTTING_LENGTH_PER_MONTH / 1000);
//
