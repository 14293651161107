import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

interface IProps {
  title: string;
}

const UpperCaseTitle = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant={'subtitle1'} className={classes.capital} component={'h2'}>
        {props.title}
      </Typography>
    </div>
  );
};

export default UpperCaseTitle;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { 
      margin: theme.spacing(1, 0, 1, 0),

      '@media (max-height: 798px)': {
        margin: theme.spacing(0, 0, 0, 0),
      },
    },
    capital: { textTransform: 'uppercase', fontWeight: 'bold', fontSize: '1em' },
  })
);
