import React, { FC, useEffect } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import * as serviceWorker from './serviceWorkerRegistration';
import { useTranslation } from 'react-i18next';

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

  const { t } = useTranslation();

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({
      onUpdate: reg => onSWUpdate(reg),
    });

    const listener = () => {
      if (document.visibilityState === 'visible') {
        navigator.serviceWorker.getRegistration().then(registration => {
          if (registration) {
            registration.update();
          }
        });
      }
    };
    document.addEventListener('visibilitychange', listener);
    return () => document.removeEventListener('visibilitychange', listener);
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <Snackbar
      open={showReload}
      message={t('General.UpdateAvailable')}
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={
        <Button color="inherit" size="small" onClick={reloadPage}>
          {t('General.Update')}
        </Button>
      }
    />
  );
};

export default ServiceWorkerWrapper;
