import React from 'react';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableFooter } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  abrasivesPerMeter,
  capitalPerMeter,
  changeInPercentage,
  energyPerMeter,
  maintenancePerMeter,
  personalPerMeter,
  totalCostsPerMeter,
  waterPerMeter,
} from '../../../calculation/calculation';
import { ProcessingMethod } from '../../../data/types';
import { formatDecimalNumber, formatPercentage } from '../../../utils/formatting';
import InfoBox from '../../common/InfoBox';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: { padding: theme.spacing(1, 1, 1, 1) },
    head: {
      borderTopStyle: 'solid',
      borderTop: '2px',
      borderTopColor: theme.palette.secondary.main,
      borderBottom: '2px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.secondary.main,
    },
    footer: {
      fontSize: 16,
      fontWeight: 'bold',
      borderTopStyle: 'solid',
      borderTop: '2px',
      borderTopColor: theme.palette.secondary.main,
      borderBottom: '2px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.secondary.main,
    },
    body: {
      fontSize: 16,
      fontWeight: 'bold',
      borderStyle: 'none',
      paddingTop: theme.spacing(2),
    },
  })
)(TableCell);

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        //  backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

function createData(
  name: string,
  costForSuspension: any,
  costForInjection: any,
  showInfo: boolean,
  infoTitle: string | null,
  infoDescription: string | null
) {
  //   const costForSuspension = constants[ProcessingMethod.suspension][cost];
  // const costForInjection = constants[ProcessingMethod.injection][cost] ;
  return {
    name,
    suspension: formatDecimalNumber(costForSuspension),
    injection: formatDecimalNumber(costForInjection),
    percent: formatPercentage(changeInPercentage(costForSuspension, costForInjection)),
    showInfo,
    infoTitle,
    infoDescription,
  };
}

interface IProps {
  speedSuspension: number;
  speedInjection: number;
}

export default function CostDetailTable(props: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const rows = [
    createData(
      t('Graph.Table.Energy'),
      energyPerMeter(props.speedSuspension, ProcessingMethod.suspension),
      energyPerMeter(props.speedInjection, ProcessingMethod.injection),
      false,
      null,
      null
    ),
    createData(
      t('Graph.Table.AbrasiveMedium'),
      abrasivesPerMeter(props.speedSuspension, ProcessingMethod.suspension),
      abrasivesPerMeter(props.speedInjection, ProcessingMethod.injection),
      false,
      null,
      null
    ),
    createData(
      t('Graph.Table.Maintenance'),
      maintenancePerMeter(props.speedSuspension, ProcessingMethod.suspension),
      maintenancePerMeter(props.speedInjection, ProcessingMethod.injection),
      true,
      t('Graph.Table.MaintenanceInfoTitle'),
      t('Graph.Table.MaintenanceInfoDescription')
    ),
    createData(
      t('Graph.Table.Water'),
      waterPerMeter(props.speedSuspension, ProcessingMethod.suspension),
      waterPerMeter(props.speedInjection, ProcessingMethod.injection),
      false,
      null,
      null
    ),
    createData(
      t('Graph.Table.Staff'),
      personalPerMeter(props.speedSuspension, ProcessingMethod.suspension),
      personalPerMeter(props.speedInjection, ProcessingMethod.injection),
      true,
      t('Graph.Table.StaffInfoTitle'),
      t('Graph.Table.StaffInfoDescription')
    ),
    createData(
      t('Graph.Table.Capital'),
      capitalPerMeter(props.speedSuspension, ProcessingMethod.suspension),
      capitalPerMeter(props.speedInjection, ProcessingMethod.injection),
      true,
      t('Graph.Table.CapitalInfoTitle'),
      t('Graph.Table.CapitalInfoDescription')
    ),
  ];

  const totalCostPerHourSuspension = totalCostsPerMeter(props.speedSuspension, ProcessingMethod.suspension);
  const totalCostPerHourInjection = totalCostsPerMeter(props.speedInjection, ProcessingMethod.injection);

  return (
    <TableContainer>
      <Table size="small" className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell className={classes.suspension}>{t('Graph.Table.Costs')}</StyledTableCell>
            <StyledTableCell className={classes.suspension} align="right">
              {t('Graph.Table.ConSus')}
            </StyledTableCell>
            {props.speedInjection !== 0 && (
              <>
                <StyledTableCell className={classes.injektor} align="right">
                  {t('Graph.Table.Injektor')}
                </StyledTableCell>

                <StyledTableCell className={classes.percent} align="right">
                  {t('Graph.Table.Percent')}
                </StyledTableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <StyledTableRow key={row.name}>
              <StyledTableCell className={classes.suspension} component="th" scope="row">
                {row.name}{' '}
                {row.infoTitle && row.infoDescription && (
                  <InfoBox title={row.infoTitle} description={row.infoDescription} fontSize={16} padding={4} />
                )}
              </StyledTableCell>
              <StyledTableCell className={classes.suspension} align="right">
                {row.suspension}
              </StyledTableCell>
              {props.speedInjection !== 0 && (
                <>
                  <StyledTableCell className={classes.injektor} align="right">
                    {row.injection}
                  </StyledTableCell>

                  <StyledTableCell className={classes.percent} align="right">
                    {row.percent}
                  </StyledTableCell>
                </>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <StyledTableRow>
            <StyledTableCell className={classes.suspension} component="th" scope="row">
              {t('Graph.Table.Total')}
            </StyledTableCell>
            <StyledTableCell className={classes.suspension} align="right">
              {formatDecimalNumber(totalCostPerHourSuspension)}
            </StyledTableCell>
            {props.speedInjection !== 0 && (
              <>
                <StyledTableCell className={classes.injektor} align="right">
                  {formatDecimalNumber(totalCostPerHourInjection)}
                </StyledTableCell>
                <StyledTableCell className={classes.percent} align="right">
                  {formatPercentage(changeInPercentage(totalCostPerHourSuspension, totalCostPerHourInjection))}
                </StyledTableCell>
              </>
            )}
          </StyledTableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 320,
    },
    suspension: { color: theme.palette.primary.main },
    injektor: { color: theme.palette.other.injektor },
    percent: { color: theme.palette.grey['500'], fontSize: 11, fontWeight: 'normal' },
    infoButton: { padding: 4 },
  })
);
