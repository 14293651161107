import React, { useEffect, useState } from 'react';
import { Button, createStyles, Theme, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UserSelection from './UserSelection';
import ResultDivider from './ResultDivider';
import materialRepository from '../../data/MaterialRepository';
import { ProcessingMethod } from '../../data/types';
import { useSettingsState } from '../SettingsContext';
import FeedBarChart from './ChartComponents/FeedChart';
import useSettingsFromQuery from '../common/useSettingsFromQuery';
import CostChart from './ChartComponents/CostChart';
import RevenueChart from './ChartComponents/RevenueChart';
import { makeStyles } from '@material-ui/core/styles';
import CurrentSettingsDesktopTablet from './CurrentSettingsDesktopTablet';
import MissingValues from './ChartComponents/BuildingBlocks/MissingValues';
import InfoContainer from '../common/InfoContainer';
import useHaveSettingsChanged from '../common/useHaveSettingsChanged';
import SaveButtonGroup from '../common/SaveButtonGroup';

const ResultView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  const selectedSettings = useSettingsState();
  const appliedSettings = useSettingsFromQuery(true);
  const haveSettingsChanged = useHaveSettingsChanged();

  const [cuttingSpeed, setCuttingSpeed] = useState<Record<ProcessingMethod, number | null> | null>(
    materialRepository.getCuttingSpeedByMaterialAndThicknessAndQuality(
      selectedSettings.material,
      selectedSettings.thickness,
      selectedSettings.quality
    )
  );

  React.useEffect(() => {
    if (appliedSettings) {
      const result = materialRepository.getCuttingSpeedByMaterialAndThicknessAndQuality(
        appliedSettings.material,
        appliedSettings.thickness,
        appliedSettings.quality
      );
      setCuttingSpeed(result);
    }
  }, [appliedSettings]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);




  return (
    <>
      <InfoContainer />
      <div style={haveSettingsChanged ? { filter: 'blur(3px)' } : { filter: 'blur(0px)' }}>
        {!isMobile && (
          <>
            <CurrentSettingsDesktopTablet appliedSettings={appliedSettings} />
          </>
        )}
        <ResultDivider title={t('Graph.Feed')} />
        {cuttingSpeed ? (
          <FeedBarChart speedInjection={cuttingSpeed.injection ?? 0} speedSuspension={cuttingSpeed.suspension ?? 0} />
        ) : (
          <MissingValues />
        )}

        <ResultDivider title={t('Graph.Costs')} />
        {cuttingSpeed ? (
          <CostChart speedInjection={cuttingSpeed.injection ?? 0} speedSuspension={cuttingSpeed.suspension ?? 0} />
        ) : (
          <MissingValues />
        )}

        <ResultDivider title={t('Graph.Revenue')} />

        {cuttingSpeed?.injection && cuttingSpeed && appliedSettings ? (
          <RevenueChart
            speedInjection={cuttingSpeed.injection ?? 0}
            speedSuspension={cuttingSpeed.suspension ?? 0}
            hourRate={appliedSettings.hourRate}
          />
        ) : (
          <MissingValues />
        )}
        {isMobile && (
          <>
            <div className={classes.container}>
              <Button variant={'text'} to={'/'} disabled={false} color={'primary'} component={Link}>
                {t('Graph.Back')}
              </Button>
            </div>
          </>
        )}
        <>
          <div className={classes.buttonGroup}>
            <SaveButtonGroup showRequest={true} />
          </div>
          <div className={classes.spacing} />
        </>

      </div>
      {isMobile && <UserSelection appliedSettings={appliedSettings} />}
    </>
  );
};

export default ResultView;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { display: 'flex', width: '100%', height: 70, justifyContent: 'center' },
    text: { alignSelf: 'center' },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: "center"
      }
    },
    spacing: { height: 60 },
    missing: { height: 200, padding: theme.spacing(4) },
  })
);
