import React, { useEffect } from 'react';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import ResultView from './pages/ResultPage/ResultView';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Theme, useMediaQuery } from '@material-ui/core';
import { SettingsProvider } from './pages/SettingsContext';
import CalculationList from './pages/SavedCalculations/CalculationList';
import Page from './pages/Page';

const App = () => {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', function (event: any) {
      event.userChoice.then(function (result: any) {
        if (result.outcome !== 'dismissed') {
          window.trackboxx('trackGoal', 'BZ6HLILSBU', 0);
          // User accepted! Send to analytics
        }
      });
    });
  }, []);

  return (
    <SettingsProvider>
      <BrowserRouter>
        <Switch>
          <Page>
            <Route exact={true} path={'/'} component={SettingsPage} />
            <Route exact={true} path={'/result'} component={isMobile ? ResultView : SettingsPage} />
            <Route path={'/saved'} component={isMobile ? CalculationList : SettingsPage} />
          </Page>
        </Switch>
      </BrowserRouter>
    </SettingsProvider>
  );
};

export default App;
