import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import strings_de from './translations/de.json';
import strings_en from './translations/en.json';
// todo: Switch language depending on browser language
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      de: {
        translation: strings_de,
      },
      en: {
        translation: strings_en,
      },
    },
   // lng: 'de',
    fallbackLng: 'en', // use en if detected lng is not available

    keySeparator: '.',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
