import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UpperCaseTitle from './UpperCaseTitle';
import RateSlider from './RateSlider';

interface IProps {
  handleHourRate: (handleHourRate: number) => void;
  selectedRate: number;
}

const HourRate = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [value, setValue] = useState<number>(125);

  useEffect(() => {
    setValue(props.selectedRate);
  }, [props.selectedRate]);

  return (
    <div>
      <UpperCaseTitle title={t('Calculator.Rate')} />
      <div className={classes.container}>
        <RateSlider value={value} setValue={setValue} handleHourRate={props.handleHourRate} />
      </div>
    </div>
  );
};

export default HourRate;

const useStyles = makeStyles(() =>
  createStyles({
    container: { display: 'flex', justifyContent: 'center' },
    input: { flexGrow: 1 },
  })
);
