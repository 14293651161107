import React from 'react';
import { Button, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSettingsState } from '../SettingsContext';
import { useHistory, useLocation } from 'react-router-dom';
import settingsRepository from '../../data/SettingsRepository';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IProps {
  showRequest: boolean;
}

const SaveButtonGroup = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const settings = useSettingsState();
  const history = useHistory();
  const { pathname } = useLocation();
  const showSavedEntries = pathname === '/saved';

  function save() {
    settingsRepository.saveMaterialSettings(settings, new Date());
    history.push({ pathname: '/saved' });
    window.trackboxx('trackGoal', 'QUS5RFBLNB', 0);
  }

  return (
    <>
      {!showSavedEntries && (
        <Button
          variant={'contained'}
          id={'save'}
          className={classes.root}
          disabled={false}
          onClick={save}
          color={'primary'}
        >
          {t('Calculator.Save')}
        </Button>
      )}
      {props.showRequest && (
        <Button
          variant={'contained'}
          className={classes.root}
          component={'a'}
          href={'mailto:sales@consus-gmbh.com'}
          disabled={false}
          color={'secondary'}
          id={'sample'}
          onClick={() => window.trackboxx('trackGoal', 'GZPDXFNJED', 0)}
        >
          {t('Calculator.Request')}
        </Button>
      )}
    </>
  );
};

export default SaveButtonGroup;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 55,
      width: 260,
      color: theme.palette.common.white,
      margin: theme.spacing(0.5),
      fontWeight: 'bold',
      fontSize: 16,
      fontVariant: 'normal',
      textTransform: 'none',
    },
    Snackbar: { backgroundColor: theme.palette.success.main, zIndex: 2000 },
  })
);
