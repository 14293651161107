import { BarItemProps } from '@nivo/bar';
import * as React from 'react';
import SVGText from './SVGText';
import { useTranslation } from 'react-i18next';
import NoInjektorData from './NoInjektorData';
import {formatDecimalNumber, formatPercentage} from '../../../../utils/formatting';

const CostsBarChart = (props: BarItemProps) => {
  const { t } = useTranslation();
  const { x, y, width, height, color, label, data } = props;
  const isPercentageNegative = data.data.changeInPercent && data.data.changeInPercent < 0;
  return (
    <g transform={`translate(${x}, ${y})`}>
      <rect width={width} height={height} fill={color} strokeWidth="0" stroke="white" />
      {label ? (
        <SVGText width={width} color={color}>
          {formatDecimalNumber(Number(label), 2, 2)}
          {data.data.format && data.data.format}
        </SVGText>
      ) : (
        <NoInjektorData width={width} color={color} />
      )}
      {isPercentageNegative && (
        <>
          <SVGText width={width} color={color} y={-65}>
            {t('Graph.Savings')}
          </SVGText>

          <SVGText width={width} color={color} y={-45} fontSize={12}>
            {formatPercentage(Math.abs(data.data.changeInPercent as number))}
          </SVGText>
        </>
      )}
    </g>
  );
};

export default CostsBarChart;
