import {MaterialSettings} from './types';
import materialRepository from './MaterialRepository';

export default function getValidSettings(settings: MaterialSettings) {
    const existing = materialRepository.doesThicknessExist(settings.material, settings.thickness);
    return existing ? settings : {
        ...settings,
        thickness: materialRepository.getDefaultThicknessForMaterial(settings.material),
    };
}
