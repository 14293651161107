import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

interface IProps {
  subtitle: string;
  value: string;
}

const InfoBoxConSusItem = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.descriptionContainer}>
      <Typography className={classes.subtitle} variant={'subtitle1'}>
        {props.subtitle}
      </Typography>
      <Typography className={classes.value} variant={'body1'}>
        {props.value}
      </Typography>
    </div>
  );
};

export default InfoBoxConSusItem;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: { textAlign: 'left', fontSize: 12, color: theme.palette.primary.main },
    value: { color: theme.palette.primary.main, fontSize: 16, fontWeight: 'bold' },
    descriptionContainer: { padding: theme.spacing(0, 0, 0, 0), minWidth: 120 },
  })
);
