import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { useTranslation } from "react-i18next";

const MissingValues = () => {
  const classes = useStyles()
  const { t } = useTranslation();

    return (
      <div className={classes.container}>
        <div className={classes.missing}>{t('Graph.Missing')}</div>
      </div>
    );
};

export default MissingValues;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { display: 'flex', width: '100%', height: 70, justifyContent: 'center' },
    missing: { height: 200, padding: theme.spacing(4) },
  })
);
